import Swiper from 'swiper';

export default function () {
  let swiper = new Swiper('.swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    keyboard: true,
    loop:true,
    speed: 300,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    breakpoints: {
      // when window width is <= 768
      768: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
      },
    }
  });

  $('.js-swiper-container-wrapper').css('opacity', 1);

  return swiper;
}
