import ScrollMagic from 'scrollmagic';
import { TweenMax, TweenLite, Linear, TimelineMax } from 'gsap'

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

export default function () {

  var controller = new ScrollMagic.Controller();

  var options = {
    triggerElement: '.js__indicator',
    duration: 349,
    offset: 0,
    triggerHook: 0
  };

  var blockPinkTwee = TweenMax.to(".js__block-pink-animation", 2, {y: 55});
  var blockBlueTwee = TweenMax.to(".js__block-blue-animation", 2, {y: 90});
  var blockRedTwee = TweenMax.to(".js__block-red-animation", 2, {y: 172});
  var blockGreenTwee = TweenMax.to(".js__block-green-animation", 2, {y: 268});
  var blockHandTwee = TweenMax.to(".js__block-hand-animation", 2, {x: 320});

  new ScrollMagic.Scene(options).setTween(blockPinkTwee).addTo(controller);
  new ScrollMagic.Scene(options).setTween(blockBlueTwee).addTo(controller);
  new ScrollMagic.Scene(options).setTween(blockRedTwee).addTo(controller);
  new ScrollMagic.Scene(options).setTween(blockGreenTwee).addTo(controller);
  new ScrollMagic.Scene(options).setTween(blockHandTwee).addTo(controller);

  // new ScrollMagic.Scene({
  //   triggerElement: '.js__main-container',
  //   duration: 5000,
  // }).setTween(TweenMax.to('.js__right-lego', 1, {
  //   y: 4450,
  //   ease: Power0.easeNone,
  // })).addTo(controller);
  //
  // new ScrollMagic.Scene({
  //   triggerElement: '.js__main-container',
  //   duration: 5500,
  //   offset: 800,
  // }).setClassToggle('.lego-left', 'opacity-1')
  //   .setTween(TweenMax.to('.js__left-lego', 1, {
  //     ease: Power0.easeNone,
  //     y: 6350,
  //   }))
  //   .addTo(controller);
}


