import '../styles/main.scss';

import PopperJs from 'popper.js';
import Validation from 'jquery-validation';
import ScrollTo from 'jquery.scrollto';
import Bootstrap from 'bootstrap';
import BootstrapSelect from 'bootstrap-select';
import $ from 'jquery';

window.$ = $;
import headerInit from './header.js';
import contactsInit from './contacts.js';
import partnersInit from './partners';
import blockAnimation from './block-animation.js';
import { breakpoints } from "./constants";

$(() => {
  let partnersSwiper = partnersInit();
  headerInit(partnersSwiper);
  contactsInit();

  if (window.innerWidth >= breakpoints.md) {
    blockAnimation();
  }
});
