import { breakpoints } from './constants.js';
import { throttle } from './utilities'

export default function (partnersSwiper) {
  const $window = $(window),
    $body = $('body'),
    $navbar = $('.js-navbar'),
    $navbarCollapse = $navbar.find('.js-navbar-collapse'),
    $intro = $('.js-intro'),
    $toTop = $('.js-to-top'),
    $sectionFirst = $('.js-section-first'),
    $navbarLogo = $('.js-navbar-logo'),
    $navbarWrapper = $('.js-navbar-wrapper');


  let navbarHeight = 81,
    maxLogoMargin = 41,
    fadeDuration = 800,
    timerDelay = 100,
    lastScrollTop = 0,
    isScrolled = false,
    scrollTo = false,
    minLogoHeight = 61,
    maxLogoHeight = 131,
    navbarLogoHeight = maxLogoHeight - $window.scrollTop() / 5,
    navbarLogoMargin = maxLogoMargin - $window.scrollTop() / 9,
    width = window.innerWidth,
    showToTop;

  /* ScrollSpy */

  $body.scrollspy({
    target: '.js-navbar',
    offset: navbarHeight
  });

  /* Scroll to top */

  $toTop.click((e) => {
    e.preventDefault();
    $body.scrollTo(0, {duration: fadeDuration});
  });

  $window.on('resize', () => {
    if (window.innerWidth !== width) {
      $navbarCollapse.collapse('hide');
    }

    width = window.innerWidth;
  });

  if (width >= breakpoints.xl) {
    $window.on('scroll', () => {
      navbarLogoHeight = maxLogoHeight - $window.scrollTop() / 5;
      navbarLogoMargin = maxLogoMargin - $window.scrollTop() / 9;

      $navbarLogo.css('height', navbarLogoHeight < minLogoHeight ? minLogoHeight : navbarLogoHeight);
      $navbarLogo.css('margin', `${navbarLogoMargin < 1 ? 0 : navbarLogoMargin}px 0`);

      if (showToTop) {
        window.clearTimeout(showToTop);
      }

      showToTop = window.setTimeout(() => {
        $toTop.toggleClass('visible', $window.scrollTop() > 250)
      }, timerDelay);
    });
  }

  /* Transparent navbar */

  const transparentNavbar = () => {
    let transitionHeightOffset = 70;
    let isTransparent = $window.scrollTop() <= ($intro.innerHeight() - navbarHeight - transitionHeightOffset);

    $navbar.toggleClass('navbar-transparent', isTransparent);
    $navbarWrapper.toggleClass('navbar-wrapper-transparent', isTransparent);
  };

  $window.on('scroll', transparentNavbar);

  $navbarCollapse.on('show.bs.collapse', () => {
    $navbar.removeClass('navbar-transparent');
    $navbarWrapper.removeClass('navbar-wrapper-transparent');
    $window.off('scroll', transparentNavbar);
  });

  $navbarCollapse.on('hidden.bs.collapse', () => {
    transparentNavbar();
    $window.on('scroll', transparentNavbar);
  });

  /* Navbar hide on scroll and collapse */

  const hideOnScrollNavbar = () => {
    if (!scrollTo && isScrolled) {
      let scrollTop = $window.scrollTop();
      let hideNavbar = scrollTop > lastScrollTop;

      if (scrollTop > 0) {
        $navbarWrapper.toggleClass('navbar-hidden', hideNavbar);
      }
      lastScrollTop = scrollTop;
    }

    isScrolled = true;
  };

  const collapseOnScrollNavbar = () => {
    if ($navbarCollapse.hasClass('show')) {
      $navbarCollapse.collapse('hide');
    }
  };

  const throttledCollapseOnScrollNavbar = throttle(collapseOnScrollNavbar, 200);

  (() => {
    $navbarWrapper.css('opacity', 1);
    $navbarLogo.css('height', navbarLogoHeight < minLogoHeight ? minLogoHeight : navbarLogoHeight);
    $navbarLogo.css('margin', `${navbarLogoMargin < 1 ? 0 : navbarLogoMargin}px 0`);

    $window.on('scroll', hideOnScrollNavbar);

    $navbarCollapse.on('show.bs.collapse', () => {
      $navbarWrapper.removeClass('navbar-hidden');
      $window.off('scroll', hideOnScrollNavbar);
      $window.on('scroll', throttledCollapseOnScrollNavbar);
    });

    $navbarCollapse.on('hidden.bs.collapse', () => {
      $window.off('scroll', throttledCollapseOnScrollNavbar);
      $window.on('scroll', hideOnScrollNavbar);
    });
  })();

  /* Scroll to target */

  $('a[data-target]').click(function (e) {
    e.preventDefault();

    // Reset swiper delay timer to prevent scroll freeze on slide change
    partnersSwiper.autoplay.stop();
    partnersSwiper.autoplay.start();

    scrollTo = true;

    let $target = $($(this).data('target')),
      $container = $target.children('.container'),
      offset = -navbarHeight,
      isExpanded = $navbarCollapse.hasClass('show');
    if ($container.length === 1 || $container.length === 2) {
      $target = $($container[0]);
      offset -= ($sectionFirst.innerHeight() - $sectionFirst.height()) / 2;
    }

    if (!isExpanded) {
      $window.off('scroll', hideOnScrollNavbar);
    }

    $body.scrollTo($target, {
      duration: fadeDuration,
      offset: offset,
      onAfter: () => {
        if (isExpanded) {
          $navbarCollapse.collapse('hide');
        } else {
          $window.on('scroll', hideOnScrollNavbar);
        }
        setTimeout(() => {
          $navbarWrapper.removeClass('navbar-hidden');
          scrollTo = false;
        }, 100);
      }
    });
  });
};
