import { emailRegex } from './constants.js';

export default function () {

  const $select = $('.js-select');
  const $contact = $('.js-contact');

  $select.selectpicker({
    style: 'btn-input'
  });

  $select.on('change', () => {
    if ($select.val() === 'query' || $select.val() === 'request_demo') {
      let $errorMessage = $select.next().next();

      $select.closest('.bootstrap-select').addClass('is-valid').removeClass('is-invalid');

      if($errorMessage.hasClass('error')){
        $errorMessage.remove();
      }
    }
  });

  const submit = ($form) => {
    const $contactSubmit = $form.find('.js-contact-submit');
    const $contactSubmitChildren = $contactSubmit.children().not('.spinner-block');
    const $contactFields = $form.find('input[name="name"]')
      .add($form.find('input[name="email"]'))
      .add($form.find('textarea[name="message"]'));
    const $contactSelects = $form.find('.js-select');
    const $contactAlert = $form.find('.js-contact-alert');

    $contactAlert.stop(true, false).fadeOut(300);
    $contactSubmit.addClass('loading').prop('disabled', true);
    $contactSubmitChildren.addClass('invisible');

    // Serialize the form data.
    const formData = $form.serialize();

    // Submit the form using AJAX.
    $.post($form.attr('action'), formData, (response) => {

      let message = response.message;

      if (!message && response.errors) {
        message = '';
        $.each(response.errors, (key, value) => {
          message += ('<p>' + value + '</p>');
        });
      }

      $contactAlert
        .removeClass('alert-danger alert-success')
        .addClass(response.success ? 'alert-success' : 'alert-danger')
        .html(message);

      $contactAlert.stop(true, false).fadeIn(300);

      // Reset form fields if success
      if (response.success) {
        $contactFields.val('');
        $contactSelects.selectpicker('val', '');
      }

      $contactSubmit.removeClass('loading').prop('disabled', false);
      $contactSubmitChildren.removeClass('invisible');
    }, 'json');
  };

  /**
   * Add custom email validation to validator
   */
  const _addCustomEmailValidation = () => {
    $.validator.addMethod('customemail',
      function (value, element) {
        return emailRegex.test(value);
      }
    );
  };

  window.validCaptcha = () => {
    $('#hiddenRecaptcha').valid();
  };

  $contact.find('.js-contact-alert').hide();

  $contact.validate({
    ignore: ".ignore",
    rules: {
      'topic': {
        required: true
      },
      'name': {
        required: true,
        minlength: 3,
        maxlength: 255
      },
      'email': {
        required: true,
        customemail: true,
      },
      'message': {
        required: true,
        minlength: 3,
        maxlength: 1000
      },
      'hiddenRecaptcha': {
        required: () => {
          return grecaptcha.getResponse() === '';
        }
      }
    },
    messages: {
      'topic': {
        required: 'Choose a topic'
      },
      'name': {
        required: 'Enter your and your company\'s name',
        minlength: 'Your and your company\'s name must be longer than 2 symbols',
        maxlength: 'Your and your company\'s name can\'t be longer than 255 symbols'
      },
      'email': {
        required: 'Enter your email address',
        customemail: 'Please enter a valid email address'
      },
      'message': {
        required: 'Type your message to us',
        minlength: 'Your message must be longer than 2 symbols',
        maxlength: 'Your message can\'t be longer than 1000 symbols'
      },
      'hiddenRecaptcha': {
        required: 'Please verify you are not a robot'
      }
    },
    submitHandler: (form) => {
      submit($(form));
      return false;
    },
    errorElement: 'em',
    errorPlacement: (error, element) => {
      error.addClass('invalid-feedback');
      if (element.prop('type') === 'select-one') {
        error.insertAfter(element.next('.dropdown-toggle'));
      } else {
        error.insertAfter(element);
      }
    },
    highlight: (element, errorClass, validClass) => {
      $(element).addClass('is-invalid').removeClass('is-valid');
      $(element).closest('.bootstrap-select').addClass('is-invalid').removeClass('is-valid');
    },
    unhighlight: (element, errorClass, validClass) => {
      $(element).addClass('is-valid').removeClass('is-invalid');
      $(element).closest('.bootstrap-select').addClass('is-valid').removeClass('is-invalid');
    },
  });

  _addCustomEmailValidation();
};
